import { useState, useEffect } from 'react'
import axios, { AxiosResponse, AxiosError } from 'axios'

import { useTranslation } from 'react-i18next'

import { useRecoilValue } from 'recoil'
import { walletState } from '../../atoms/WalletAtom'
import { ZERO_ADDRESS } from '../../constants'

const BuyCounter = () => {
  const { t } = useTranslation()

  const walletAddress = useRecoilValue(walletState)

  const [buyCount, setBuyCount] = useState(0)

  useEffect(() => {
    if (walletAddress === '' || walletAddress === ZERO_ADDRESS)
      return setBuyCount(0)
    CheckBuyCount()
  }, [walletAddress])

  const CheckBuyCount = () => {
    axios({
      method: 'GET',
      url: `${import.meta.env.VITE_API_BASE_URI}/api/v1/users/buy_count`,
      params: {
        address: walletAddress,
      },
    })
      .then((response: AxiosResponse) => {
        setBuyCount(response.data.buy_count)
      })
      .catch((error: AxiosError) => {
        console.error('error', error)
      })
  }

  let notes = <></>
  if (buyCount >= 0 && buyCount < 10) {
    notes = (
      <>
        <div className="note white"></div>
        <div className="mater">
          <div
            className="inner"
            style={{
              width: `${(buyCount / 10) * 100}%`,
            }}
          ></div>
        </div>
        <div className="next-step-count">10</div>
      </>
    )
  } else if (buyCount >= 10 && buyCount < 30) {
    notes = (
      <>
        <div className="note gray"></div>
        <div className="mater">
          <div
            className="inner"
            style={{
              width: `${(buyCount / 30) * 100}%`,
            }}
          ></div>
        </div>
        <div className="next-step-count">30</div>
      </>
    )
  } else if (buyCount >= 30 && buyCount < 50) {
    notes = (
      <>
        <div className="note gold"></div>
        <div className="mater">
          <div
            className="inner"
            style={{
              width: `${(buyCount / 50) * 100}%`,
            }}
          ></div>
          <div className="next-step-count">50</div>
        </div>
      </>
    )
  } else if (buyCount >= 50) {
    notes = (
      <>
        <div className="note rainbow"></div>
        <div className="mater">
          <div
            className="inner"
            style={{
              width: `${(buyCount / 500) * 100}%`,
            }}
          ></div>
          <div className="next-step-count">🚀</div>
        </div>
      </>
    )
  }

  return (
    <div className="buy-counter">
      {t('components.SideNav.buyCounter.title')}: {buyCount}
      {t('components.SideNav.buyCounter.unit')}
      <br />
      <div className="notes">{notes}</div>
      <p className="txt">特典を確認する</p>
    </div>
  )
}

export default BuyCounter
